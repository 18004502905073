import { useMediaQuery } from '@mui/material';
import Link from 'next/link';

import Wrapper from '@/components/BodyLayout/Wrapper';
import Img404 from 'public/assets/404.png';

export default function Custom404() {
  const isMedia920px = useMediaQuery('(max-width : 920px)');
  return (
    <Wrapper>
      <div
        className={`h-full bg-white flex flex-col justify-center items-center m-auto rounded-lg ${
          isMedia920px ? 'px-4' : 'px-20'
        }`}
      >
        <img src={Img404.src} alt="Err 404: Not Found" className="w-100" />
        <div className={`${isMedia920px ? 'mt-12' : 'mt-4'} mb-8`}>
          <Link href="/" passHref={true}>
            <button className="bg-yellow py-2 px-6 font-semibold rounded-md">
              <span>Share My Files</span>
            </button>
          </Link>
        </div>
      </div>
    </Wrapper>
  );
}
